import React from 'react';
import "../../css/common/modal/CustomImageModal.css";

export const CustomImageModal = ({ isOpen, onClick, children }) => {

    return(
        <>
            <div className = { `modal-overlay ${ isOpen }` } onClick = { onClick }></div>
            <div className = {`custom-image-modal ${ isOpen }`}
                style = {{
                    borderRadius: "8px"
                }}
            >
                <div className = "custom-image-container" style = {{ display: "flex", justifyContent: "center", alignItem: "center", borderRadius: "8px" }}>
                    { children }
                </div>
            </div>
        </>
    );
};