import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Chart.js의 기본 구성 요소 등록
Chart.register(...registerables);

const DoughnutGraph = ({chartData}) => {
  // 데이터 설정
  const data = {
    // labels: ['레드', '블루', '옐로우', '그린'], // 레이블
    datasets: [
      {
        // 10,485,760 = 10GB
        data: [10485760 - chartData, chartData], // 데이터 값
        backgroundColor: ['#0040FF', '#FE2E2E'], // 색상
        hoverBackgroundColor: ['#FF6384', '#36A2EB'], // 호버 색상
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // 레전드(라벨) 숨기기
      },
      tooltip: {
        enabled: false, // 툴팁 숨기기
      },
    },
  };

  return (
    <div style = {{ width: "7vw", height: "11vh", padding: 0, margin: 0 }}>
        <Doughnut data = { data } option = { options } style = {{ marginLeft: "0", paddingLeft: "0" }} />
    </div>
  );
};

export default DoughnutGraph;