import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header';
// import { Link } from 'react-router-dom';
import '../../../common/modal/Modal';
import { call } from '../../../service/ApiService';
import { Toast } from '../../../common/toast/Toast';
import { useNavigate } from 'react-router-dom';

function GroupFile() {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"));
    const [ isOpen, setIsOpen ] = useState("");
    // const [ userIdx, setUserIdx ] = useState({});
    // const [ groupFile, setGroupFile ] = useState({});
    const [ isModalOpen, setIsModalOpen ] = useState(true);
    const [ isToastOpen, setIsToastOpen ] = useState(false);
    const [ rostGroup, setRostGroup ] = useState([]);
    const [ resultSet, setResultSet ] = useState({});
    const [ createGroupResponseDto, setCreateGroupResponseDto ] = useState({});

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen);
        if (isModalOpen) {
            setIsOpen("open")
            setIsModalOpen(!isModalOpen);
        } else {
            setIsOpen("")
            setIsModalOpen(!isModalOpen);
        }
    };
    
    const handleCloseModal = () => {
        setIsModalOpen(!isModalOpen);
        if (!isModalOpen) {
            setIsOpen("")
            setIsModalOpen(!isModalOpen);
        }
    };

    const setResponseDto = (e) => {
        setCreateGroupResponseDto({
            ...createGroupResponseDto,
            "userIdx": user.userId,
            "groupCapacity": 4,
            "groupName": e.target.value
        })
    };

    useEffect(() => {
        call("/groups/group", "GET", {"userIdx": user.userId})
        .then((res) => {

            setRostGroup(res.result);
        })
        .catch((res) => alert("Error ::: " + res.statusCode));
        // call("/groups/group/file", "GET", null)
        // .then((res) => alert(res))
        // .catch((res) => alert(res))
    }, [user.userId]);

    const createGroup = () => {
        setIsToastOpen(true);
        setTimeout(() => {
            setIsToastOpen(false);
        }, 3000);
        call("/groups/group", "POST", createGroupResponseDto)
        .then((res) => {

            if (res.statusCode === 200) {
                setResultSet({
                    ...resultSet,
                    "resultCode": "success",
                    "resultMessage": "그룹이 생성되었습니다."
                })
            }
            
            setIsOpen("")
            setIsModalOpen(!isModalOpen);
        }).catch(() => {
            setResultSet({
                ...resultSet,
                "resultCode": "failed",
                "resultMessage": "그룹 생성에 실패했습니다."
            })
            setIsOpen("")
            setIsModalOpen(!isModalOpen);
        })
    };

    const findGroupFiles = (groupIdx, groupName) => {
        navigate(`/files/group/${groupName}`, { state: { groupIdx }})
    };

    return (
        <div>
            <Header />
            <div className = "container" style = {{}}>
                <div className = "row">
                    <div className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className = "row">
                            <div className = "container" style = {{ width: "80%" }}>
                                {/* <Link to = "/files/group/create"><button className = "btn btn-outline-info" style = {{ float: "right" }} onClick = { handleOpenModal }>그룹 생성</button></Link> */}
                                <button className = "btn btn-outline-info" style = {{ float: "right" }} onClick = { handleOpenModal }>그룹 생성</button>
                                <div className = { `row createModal ${ isOpen }` } style = {{ paddingTop: "0.4vw" }}>
                                    <label style = {{ borderBottom: "solid 1px gray", display: "flex", justifyContent: "center", alignItems: "center" }}>그룹 만들기</label>
                                    <div className = "col-md-3 col-lg-3 col-xl-3 col-xxl-3" style = {{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <label>그룹 명</label>
                                    </div>
                                    <div className = "col-md-8 col-lg-8 col-xl-8 col-xxl-8" style = {{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <input type = "text" className = "form-control" onChange = { setResponseDto } />
                                    </div>
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <button type = "button" className = "btn btn-outline-info" style = {{ width: "100%" }} onClick = { createGroup }>생성</button>
                                    </div>
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <button type = "button" className = "btn btn-outline-danger" onClick = { handleCloseModal } style = {{ width: "100%" }}>취소</button>
                                    </div>
                                </div>
                                { isToastOpen ? <Toast resultCode = { resultSet.resultCode } resultMessage = { resultSet.resultMessage } /> : <Toast /> }
                                <div className = "row">
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label>내 그룹</label>
                                        <hr />
                                        <div className = "row">
                                            <div className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12" style = {{ display: "flex", justifyContent: "center", alignContent: "center", height: "100%" }}>
                                                { rostGroup.map((group) => 
                                                    <div className = "card" key = { group.groupIdx } style = {{ width: "100%", padding: "10px", cursor: "pointer" }} onClick = { () => findGroupFiles(group.groupIdx, group.groupName) }>
                                                        <div className = "card-title" style = {{ textAlign: "center", fontSize: "1.5rem", marginBottom: "0" }}>{ group.groupName }</div><hr />
                                                        <div className = "card-body">
                                                            <div className = "row">
                                                                <div className = "col-md-4" style = {{ border: "solid 1px black", width: "30%", height: "6vh", borderRadius: "55%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    IMG
                                                                </div>
                                                                <div className = "col-md-8" style = {{ textAlign: "center" }}>
                                                                    <label>그룹 최대 인원 : { group.groupCapacity }</label><br />
                                                                    <label>그룹 참여 인원 : { group.inGroupUsers }</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) }
                                            </div>
                                        </div>
                                    </div>
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">

                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default GroupFile;