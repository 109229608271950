import { useEffect } from 'react';
import '../toast/Toast.css';

export const Toast = ({ resultCode, resultMessage }) => {
    useEffect(() => {
        let active = document.getElementById("active")
        if (active) {
            const startTimer = setTimeout(() => {
                active.classList.add("active");
            }, 10);

            const timer = setTimeout(() => {
                active.classList.remove("active");
            }, 2800);

            // 클린업 함수: 컴포넌트가 언마운트될 때 타이머를 정리합니다.
            return () => {
                clearTimeout(startTimer);
                clearTimeout(timer);
            }
        }
    }, []);

    return (
        <div className = { `storageToast ${ resultCode }` } id = "active">
            <label>{ resultMessage }</label>
        </div>
    );
};