import React, { useState } from 'react';
import MyCarousel from '../common/carousel/Carousel';
import { useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import Header from '../common/Header';
import Doughnut from '../common/doughnut/Doughnut';
import { call } from '../service/ApiService';
// import Header from '../common/Header';

function NearStorageMain() {
    const [ userUsingInfo, setUserUsingInfo ] = useState({});
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
      const accessToken = localStorage.getItem('Access_Token');
      if (!accessToken) {
        navigate('/user/login');
      }

      call("/files/info", "GET", {"userIdx": userInfo.userId})
      .then((res) => {
        setUserUsingInfo(res.result);
      })
    }, [navigate, userInfo.userId]);

    // userUsingInfo :: {stockImage: 13, stockVideo: 0, stockMusic: 0, fileUsingByte: 3480 MB}

    return( 
        <div style = {{  }}>
            <Header />
            <MyCarousel />
            <div style = {{ marginTop: "3vh" }}>
                <div className = "container" style = {{ width: "60%" }}>
                    <div className = "row">
                        <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className = "row" style = {{ width: "100%" }}>
                                <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                    <img alt = "" src = { require("../img/main/container-logo.png") } style = {{ width: "100%" }} />
                                </div>
                                <div className = "col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                    <div className = "row">
                                        <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                            <Doughnut chartData = { userUsingInfo.fileUsingByte } />
                                        </div>
                                        <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                            <label>총: 10GB</label>
                                            <label>사용: { (userUsingInfo.fileUsingByte / 1024).toFixed(2) }MB</label>
                                        </div>
                                        <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                        </div>
                                        <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-1" style = {{ textAlign: "center" }}>
                                            <label style = {{ marginLeft: "1vw" }}>사용량</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6" style = {{ border: "solid 1px black" }}>
                            <div className = "row">
                                <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4"></div>
                                <div className = "col-md-8 col-lg-8 col-xl-8 col-xxl-8"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NearStorageMain;