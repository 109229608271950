import React from 'react';
import '../../css/common/img/CustomContainerSizeImage.css';

export const CustomContainerSizeImage = ({ imagePath, onClick }) => {
    return(
        <img 
            alt = "" 
            src = { imagePath } 
            className = "custom-containerSize-image" 
            onClick = { onClick }
        />
    );
};