import React from 'react';
import '../../css/common/img/CustomImage.css';

export const CustomImage = ({ imagePath, onClick }) => {
    return(
        <img 
            alt = "" 
            src = { imagePath } 
            className = "custom-image" 
            onClick = { onClick }
        />
    );
};