import React from 'react';
import '../../css/common/input/Input.css';

export const CustomInput = ({ placeholder, onChange }) => {
    return(
        <div className = "custom-input-container">
            <input type = "text" className = "custom-input" placeholder = "" onChange = { onChange } />
            <label className = "placeholder">{placeholder}</label>
        </div>
    );
};