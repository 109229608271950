import React, { useEffect, useState } from 'react';
import Header from '../../common/Header';
import { Link, useParams } from 'react-router-dom';
import { call } from '../../service/ApiService';
import '../sharefile/FileImage.css';
// import { fileCall } from '../../common/file-service/FileCall';

function ViewDirectory() {
    const { id } = useParams();
    const token = localStorage.getItem("Access_Token");
    const user = JSON.parse(localStorage.getItem("user"));
    // const location = useLocation();
    const [ createFolders, setCreateFolders ] = useState([]);
    const [ folders, setFolders ] = useState([]);
    const [ viewImage, setViewImage ] = useState([]);


    useEffect(() => {
        const responseDto = {
            "userId": user.userId,
            "userDirectory": id
        }
        call("/files/directory", "GET", responseDto)
        .then((res) => {
            setFolders([res.result.userFolder]);
            const imagePromises = res.result.userFile.map(async (file) => {
                const cndImageUrl = "http://58.226.156.85/cdnstorage/";
                const imageUrl = cndImageUrl + user.userDefaultDirectory + "/" + file.fileName;
            
                const response = await fetch(imageUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, 
                    },
                });
            
                if (!response.ok) {
                    throw new Error('이미지 로드 실패: ' + response.status);
                }
            
                const blob = await response.blob();
                const viewImage = new Image();
                viewImage.src = URL.createObjectURL(blob);
            
                return new Promise((resolve) => {
                    viewImage.onload = () => {
                        resolve({
                            "img": viewImage.src,
                            "fileId": file.fileId,
                            "fileName": file.fileName,
                            "imageWidth": viewImage.width,
                            "imageHeight": viewImage.height,
                            "isWide": viewImage.width > viewImage.height,
                        });
                    };
                });
            });
        
            Promise.all(imagePromises).then((images) => {
                setViewImage((prevState) => [
                    ...prevState,
                    ...images,
                ]);
            });
        })
        .catch((error) => {
            // console.error('오류 발생:', error);
        });
    }, [id, user.userId, user.userDefaultDirectory, token]);

    const createFolder = () => {
        const newFolder = {
            id: createFolders.length,
            name: "새 폴더",
            edit: true
        }
        setCreateFolders([ ...createFolders, newFolder ])
    };

    const folderNameChange = (folderId, folderName) => {
        const updatedFolders = createFolders.map((folder) =>
            folder.id === folderId ? { ...folder, id: folderId, name: folderName, edit: false } : folder
        );
        setCreateFolders(updatedFolders);
        const direcotry = id + "/" + updatedFolders[0].name
        const responseDto = {
            "userId": user.userId,
            "fileDirectory": direcotry,
            "folderName": updatedFolders[0].name,
            "depth": direcotry.split("/").length,
            "parentFolder": id
        }

        call("/files/directory", "POST", responseDto)
        .then((res) => {
            if (res.state === 200) {
                setCreateFolders([])
                setFolders({ ...folders, res })
            }
        });
    }

    // const changeFolderName = () => {
    //     if (selectedDirectory == null) {
    //         alert("폴더를 선택해주세요.");
    //         return;
    //     }
    //     call("/files/change/folder", "POST", selectedDirectory)
    //     .then((res) => {
    //         alert("폴더명이 변경되었습니다.")
    //         // user.userDirectory = selectedDirectory
    //         // localStorage.setItem("user", JSON.stringify(user))
    //         // navigator("/files/personal")
    //     })
    // };

    // const images = [
    //     { id: 1, src: 'https://via.placeholder.com/300x150', isWide: true },
    //     { id: 2, src: 'https://via.placeholder.com/150x300', isWide: false },
    //     { id: 3, src: 'https://via.placeholder.com/300x300', isWide: false },
    //     { id: 4, src: 'https://via.placeholder.com/150x150', isWide: false },
    //     { id: 5, src: 'https://via.placeholder.com/300x150', isWide: true },
    //     { id: 6, src: 'https://via.placeholder.com/150x150', isWide: false },
    //     { id: 7, src: 'https://via.placeholder.com/150x150', isWide: false },
    //     { id: 8, src: 'https://via.placeholder.com/300x150', isWide: true },
    //     { id: 9, src: 'https://via.placeholder.com/150x300', isWide: false },
    //     { id: 10, src: 'https://via.placeholder.com/150x150', isWide: false },
    //     { id: 11, src: 'https://via.placeholder.com/150x150', isWide: false },
    //     { id: 12, src: 'https://via.placeholder.com/300x150', isWide: true },
    // ];

    return(
        <div>
            <Header />
            <div className = "container" style = {{}}>
                <div className = "row">
                    <div className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className = "row">
                            <div className = "container" style = {{ width: "80%" }}>
                                <div className = "row">
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label>
                                            개인만 접근 가능한 파일입니다.<br />
                                            <span style = {{ color: "red" }}>암호화</span>되어 저장되어있습니다.
                                        </label>
                                    </div>
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <Link to = "/files/upload" state = {{ uploadType: "1", directory: id }}>
                                            <button className = "btn btn-primary" style = {{ float: "right", fontSize: "23px" }}>+ 업로드</button>
                                        </Link>
                                    </div>
                                </div>
                            </div> 
                            <hr />
                            <div className = "container mt-1" style = {{ width: "80%" }}>
                                <div className = "row" style = {{ width: "100%" }}>
                                    <button type = "button" className = "btn btn-outline-info" style = {{ float: "left", width: "15%" }} onClick={ createFolder } >폴더 생성</button>
                                    <button type = "button" className = "btn btn-outline-success" style = {{ float: "left", width: "15%", marginLeft: "15px" }}>이름 변경</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className = "row mt-3">
                <div className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12" style = {{ border: "solid 1px black", height: "55vh", overflowY: "auto" }}>
                    <div className = "row">
                        { createFolders.length > 1 ? createFolders.map((folder) => (
                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "7vw", height: "10vh", position: "relative", border: "solid 1px black" }} key = { folder.id }>
                                <div className = "card" style = {{ width: "100%" }}>
                                    <div className = "card-title">
                                        <img alt = "" src = { require("../../img/folder/CF_B_5.ico") } style = {{ width: "100%", paddingLeft: "15px", paddingRight: "15px" }} />
                                    </div>
                                    <div className = "card-body">
                                        { folder.edit ? 
                                            <input
                                                type="text"
                                                defaultValue = { folder.name }
                                                onBlur = { (e) => folderNameChange(folder.id, e.target.value) }
                                                onKeyDown = { (e) => {
                                                    if (e.key === "Enter") {
                                                        e.target.blur();
                                                    }
                                                }}
                                            /> : 
                                            folder.name
                                        }
                                    </div>
                                </div>
                            </div>
                        )) : <></> }
                        { folders[0]?.map((folder) => (
                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "7vw", height: "10vh", position: "relative", border: "solid 1px black" }} key = { folder.folderId }>
                                <div className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12" style = {{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                    <img alt = "" src = { require("../../img/folder/CF_B_5.ico") } style = {{ width: "80%" }} />
                                </div>
                                <div className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <label style = {{ textAlign: "center", width: "100%", fontSize: "14px" }}>{ folder.folderName }</label>
                                </div>
                            </div>
                        ))}
                        <div className = "grid-layout" style = {{ padding: "10px" }}>
                            { viewImage.map((file) => (
                                <div 
                                    key = { file.fileId }
                                    className = {`
                                        grid-item 
                                        ${
                                            file.imageWidth >= 500 && file.imageWidth < 700 ? '' : 
                                            file.imageWidth >= 700 && file.imageWidth < 900 ? 'span-2' : 
                                            file.imageWidth >= 900 && file.imageWidth < 1100 ? 'span-3' : 
                                            file.imageWidth >= 1100 && file.imageWidth < 1300 ? 'span-4' : 
                                            file.imageWidth >= 1300 && file.imageWidth < 1500 ? 'span-5' : 
                                            file.imageWidth >= 1500 && file.imageWidth < 1700 ? 'span-6' : 
                                            file.imageWidth >= 1700 && file.imageWidth < 1900 ? 'span-7' : 
                                            file.imageWidth >= 1900 && file.imageWidth < 2100 ? 'span-8' : 
                                            file.imageWidth >= 2100 && file.imageWidth < 2300 ? 'span-9' : ''
                                        }
                                        ${
                                            file.imageHeight >= 500 && file.imageHeight < 600 ? '' : 
                                            file.imageHeight >= 600 && file.imageHeight < 700 ? 'tail-2' : 
                                            file.imageHeight >= 700 && file.imageHeight < 800 ? 'tail-3' : 
                                            file.imageHeight >= 800 && file.imageHeight < 900 ? 'tail-4' : 
                                            file.imageHeight >= 900 && file.imageHeight < 1000 ? 'tail-5' : 
                                            file.imageHeight >= 1000 && file.imageHeight < 1100 ? 'tail-6' : 
                                            file.imageHeight >= 1100 && file.imageHeight < 1200 ? 'tail-7' : 
                                            file.imageHeight >= 1200 && file.imageHeight < 1300 ? 'tail-8' : 
                                            file.imageHeight >= 1300 && file.imageHeight < 1400 ? 'tail-9' : ''
                                        }
                                    `}
                                >
                                    <img alt = { file.fileName } src = { file.img } />
                                </div>
                            ))}
                        </div>
                        
                        
                        {/* <MasonryLayout files = { files } /> */}
                        {/* <ShuffleLayout files = { files } /> */}

                        
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ViewDirectory