import React from 'react';
import Header from '../../../common/Header';

function CreateGroup() {
    return (
        <div>
            <Header />
            <div className = "container mt-3">
                <div className = "row">
                    <h4>그룹 생성</h4>
                    <div className = "col-md-10 col-lg-10 col-xl-10 col-xxl-10 offset-md-1 offset-lg-1 offset-xl-1 offset-xxl-1" style = {{ border: "solid 1px gray", borderRadius: "5px" }}>
                        <div className = "row" style = {{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div className = "col-md-4" style = {{ textAlign: "center" }}>그룹 명</div>
                            <div className = "col-md-7" style = {{ marginLeft: "1vw" }}>
                                <input type = "text" className = "form-control" name = "groupName" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}
export default CreateGroup;