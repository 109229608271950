import React, { useEffect, useRef, useState } from 'react';
import Header from '../../common/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { call } from '../../service/ApiService';

function FileUpload() {
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user"));
    const [ uploadFile, setUploadFile ] = useState([]);
    const [ uploadData, setUploadData ] = useState({});
    const [ uploadType, setUploadType ] = useState(null);
    const [ group, setGroup ] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state && location.state.uploadType) {
            setUploadType(location.state.uploadType);
        }

        if (location.state && location.state.groupIdx && location.state.groupName) {
            setGroup({
                "groupIdx": location.state.groupIdx,
                "groupName": location.state.groupName
            });
        }

        setUploadData({
            "userId": Number(user.userId),
            "directory": location.state.directory,
            "uploadType": location.state.uploadType
        });
    }, [location.state, user.userId]);

    const [dragOver, setDragOver] = useState(false);
    const [file, setFile] = useState([]);
    const fileInputRef = useRef(null);

    const handleFileClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        setFile(Array.from(event.target.files));
        setUploadFile(event.target.files)
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!dragOver) {
            setDragOver(true);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);

        // 파일들을 가져옵니다.
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];
            // console.log(file)
            // 파일 처리 로직을 여기에 작성하세요.
            setFile([ ...files, ...Array.from(file) ])
            setUploadFile(files);
            // console.log("files : ", files);
        }
    };

    const fileSave = () => {
        const formData = new FormData();
        Array.from(uploadFile).forEach((file) => {
            formData.append("file", file);
        });
        formData.append("userId", uploadData.userId);
        formData.append("directory", uploadData.directory);
        formData.append("uploadType", uploadData.uploadType);
        if (uploadType === "2") {
            formData.append("groupIdx", group.groupIdx);
            formData.append("groupName", group.groupName);
        }

        if (file.length > 0) {
            call("/files/upload", "POST", formData)
            .then((res) => {
                alert("파일이 업로드 되었습니다.");
                navigate(uploadType === "1" ? "/files/personal" : "/files/group");
            }).catch((error) => {
                alert(error)
            })
        } else {
            alert('업로드할 파일을 선택해주세요.');
        }
    };

    return(
        <div>
            <Header />
            <div className = "container">
                <div className = "container" style = {{ width: "80%" }}>
                    <div className = "row">
                        <div className = "col-md-8" style = {{ textAlign: "center" }}>
                            <div
                                className={`dropzone ${dragOver ? 'drag-over' : ''}`}
                                onDragEnter={ handleDragEnter }
                                onDragLeave={ handleDragLeave }
                                onDragOver={ handleDragOver }
                                onDrop={ handleDrop }
                                onClick={ handleFileClick }
                                style={{ border: "dashed 3px #ccc", width: "100%", height: "35vh", display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <input
                                    type="file"
                                    multiple
                                    ref={ fileInputRef }
                                    style = {{ display: "none" }}
                                    onChange = { handleFileChange }
                                    name = "file"
                                />
                                <label style={{ color: "gray", verticalAlign: "middle", cursor: "pointer" }}>+ 파일 드래그 앤 드롭 또는 클릭하여 선택</label>
                            </div>
                        </div>
                        <div className = "col-md-4" style = {{ display: "flex", justifyContent: "center" }}>
                            <label>
                                { file.length > 0 && (
                                    <ul style = {{ listStyle: "none", margin: "0", padding: "0" }}>
                                        {file.map((file, index) => (
                                            <li key={index}>{file.name}</li>
                                        ))}
                                    </ul>
                                )}
                            </label>
                        </div>
                    </div>
                    <hr />
                    <div className = "row" style = {{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className = "col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                            스토리지에/저장하는/경로가/여기/있어야/할듯
                        </div>
                        <div className = "col-md-3 col-lg-3 col-xl-3 col-xxl-3" style = {{ display: "flex", alignItems: "center" }}>
                            <button type = "button" className = "btn btn-outline-secondary">...</button>
                            <input type = "radio" name = "fileType" value = "1" style = {{ marginLeft: "2vw" }} checked = { uploadType === "1" } /> 개인
                            <input type = "radio" name = "fileType" value = "2" style = {{ marginLeft: "2vw" }} checked = { uploadType === "2" } /> 그룹
                        </div>
                        <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                            <button className = "btn btn-outline-primary" type = "button" style = {{ width: "100%" }} onClick={ fileSave }>저장</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default FileUpload;