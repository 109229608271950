import React, { useEffect, useState } from 'react';
import { CustomContainerSizeImage } from '../image/CustomContainerSizeImage';

export function CustomCarousel({ imageList, clickImage, imgIndex }) {
    useEffect(() => {
        imageList.forEach((img, index) => {
            if (img.fileId === clickImage) {
                setCurrentIndex(index);
            }
        });
    }, [clickImage, imageList]);

    const [currentIndex, setCurrentIndex] = useState(imgIndex); // 초기값을 imgIndex로 설정

    const imagePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? imageList.length - 1 : prevIndex - 1));
    };

    const imageNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === imageList.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div id="imageCarousle" className="carousel slide" data-bs-ride="carousel" style = {{ width: "100%", borderRadius: "8px" }}>
            <div className="carousel-inner" style = {{ height: "100%", borderRadius: "8px" }}>
                {imageList.map((file, index) => (
                    <div className={`carousel-item ${index === currentIndex ? "active" : ""}`} key={file.fileId} style = {{ height: "100%" }}>
                        <CustomContainerSizeImage imagePath={file.img} style = {{  }} />
                    </div>
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#imageCarousle" data-bs-slide="prev" onClick={imagePrev}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#imageCarousle" data-bs-slide="next" onClick={imageNext}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}