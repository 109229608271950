import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { call } from '../../service/ApiService';
import Header from '../../common/Header';
import '../../css/group/inviteGroup.css';
import { CustomInput } from '../../common/input/CustomInput';
import { CustomButton } from '../../common/button/CustomButton';
import { Toast } from '../../common/toast/Toast';
import { CustomImage } from '../../common/image/CustomImage';
import { CustomCarousel } from '../../common/carousel/CustomCarousel';
import { CustomImageModal } from '../../common/modal/CustomImageModal';

function ViewGroup() {
    const { groupName } = useParams();
    const location = useLocation();
    const groupIdx = location.state?.groupIdx; 
    const token = localStorage.getItem("Access_Token");
    const user = JSON.parse(localStorage.getItem("user"));
    const [ nickname, setNickname ] = useState({});
    const [ findNicknameResult, setFindNicknameResult ] = useState({});
    const [ groupFile, setGroupFile ] = useState([]);
    const [ fileCreateDate, setFileCreateDate ] = useState([]);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ inviteUser, setInviteUser ] = useState({});
    const [ isToastOpen, setIsToastOpen ] = useState(false);
    const [ resultSet, setResultSet ] = useState({});
    const [ imageDetail, setImageDetail ] = useState(0);
    const [ imgIndex, setImgIndex ] = useState(0);
    const [ isImageModal, setIsImageModal ] = useState(false);

    useEffect(() => {
        call("/groups/group/file", "GET", {
            "groupIdx": groupIdx, 
            "userIdx": user.userId,
            "groupName": groupName,
            "fileDirectory": user.userDefaultDirectory
        })
        .then((res) => {
            setFileCreateDate(res.result.metaDateList);
            // console.log(res.result)
            const imagePromises = res.result.rostGroupList.map(async (file) => {
                const cdnImageUrl = "http://58.226.156.85/cdnstorage/";
                const imageUrl = cdnImageUrl + user.userDefaultDirectory + "/" + file.fileName;
            
                const response = await fetch(imageUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, 
                    },
                });
            
                if (!response.ok) {
                    throw new Error('이미지 로드 실패: ' + response.status);
                }
            
                const blob = await response.blob();
                const viewImage = new Image();
                viewImage.src = URL.createObjectURL(blob);
            
                return new Promise((resolve) => {
                    viewImage.onload = () => {
                        resolve({
                            "img": viewImage.src,
                            "fileId": file.fileIdx,
                            "fileName": file.fileName,
                            "imageWidth": viewImage.width,
                            "imageHeight": viewImage.height,
                            "fileModifiedDate": file.filesModifiedDate.split(" ")[0],
                            "isWide": viewImage.width > viewImage.height,
                        });
                    };
                });
            });
        
            Promise.all(imagePromises).then((images) => {
                setGroupFile((prevState) => [
                    ...prevState,
                    ...images,
                ]);
                // console.log(images)
            });
        }).catch((error) => {
            // console.log("error : ", error);
        })
    }, [groupIdx, groupName, token, user.userDefaultDirectory, user.userId]);
    
    const inviteGroup = () => {
        setIsModalOpen(!isModalOpen);
    };

    const findUserNickname = (e) => {
        setNickname({ ...nickname, "nickname": e.target.value });
    };

    const findUser = () => {
        call("/users/info/nickname", "GET", nickname)
        .then((res) => {
            setInviteUser({
                "groupsIdx": groupIdx,
                "groupsAdminIdx": user.userId,
                "inviteUsersIdx": res.result.usersIdx
            })
            setFindNicknameResult(res.result);
        })
        .catch((error) => {
            if (error.status === 500 || error.status === "500") {
                setFindNicknameResult({});
            }
        });
    }

    const userInvite = () => {
        setIsToastOpen(true);
        setTimeout(() => {
            setIsToastOpen(false);
        }, 3000);

        call("/groups/group/invite", "POST", inviteUser)
        .then((res) => {    
            if (res.statusCode === 200) {
                setResultSet({
                    ...resultSet,
                    "resultCode": "success",
                    "resultMessage": "그룹에 초대되었습니다."
                })
            }
        })
        .catch((error) => {
            setResultSet({
                ...resultSet,
                "resultCode": "error",
                "resultMessage": "그룹 초대에 실패했습니다."
            })
        })
    };

    const clickImage = (fileId, index) => {
        setImgIndex(index);
        setImageDetail(fileId);
        setIsImageModal(!isImageModal)
    };

    const modalBackgroundClick = () => {
        setIsImageModal(!isImageModal);
    };

    return(
        <div>
            <Header />
            <div className = "container" style = {{}}>
                <div className = "row">
                    <div className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className = "row">
                            <div className = "container" style = {{ width: "80%" }}>
                                <div className = "row">
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label>
                                            그룹에 속한 이용자만 접근 가능한 파일입니다.<br />
                                            <span style = {{ color: "red" }}>암호화</span>되어 저장되어있습니다.
                                        </label>
                                    </div>
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <Link to = "/files/upload" state = {{ uploadType: "2", directory: user.userDefaultDirectory, groupIdx: groupIdx, groupName: groupName }}>
                                            <button className = "btn btn-primary" style = {{ float: "right", fontSize: "23px" }}>+ 업로드</button>
                                        </Link>
                                    </div>
                                </div>
                            </div> 
                            <hr />
                            <div className = "container mt-1" style = {{ width: "80%" }}>
                                <div className = "row" style = {{ width: "100%" }}>
                                    <button 
                                        type = "button" 
                                        className = "btn btn-outline-success" 
                                        style = {{ 
                                            float: "left", 
                                            width: "15%", 
                                            marginLeft: "15px" 
                                        }}
                                        onClick = { inviteGroup }
                                    >
                                        그룹에 초대
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className = "modalConatiner">
                    <div className = {`inviteGroupModal ${isModalOpen ? "open" : ""}`} style = {{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "8px" }}>
                        <h4>그룹 초대</h4>
                        <hr />
                        <div className = "row">
                            <div className = "col-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                                <CustomInput placeholder = "UserNickname" id = "nickname" onChange = { findUserNickname } />
                            </div>
                            <div className = "col-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <CustomButton buttonText = "검색" onClick = { findUser } />
                            </div>
                        </div>
                        { Object.keys(findNicknameResult).length > 0 ? (
                            <div className = "row">
                                <table className = "table">
                                    <thead>
                                        <tr>
                                            <td style = {{ textAlign: "center", verticalAlign: "middle" }}>닉네임</td>
                                            <td style = {{ textAlign: "center", verticalAlign: "middle" }}>비고</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style = {{ textAlign: "center", verticalAlign: "middle" }}>{findNicknameResult.usersNickname}</td>
                                            <td style = {{ textAlign: "center", verticalAlign: "middle" }}><CustomButton buttonText = { "초대" } onClick = { userInvite } /></td>
                                        </tr>
                                        </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className = "container">
                                <h4>검색 결과가 없습니다.</h4>
                            </div>
                        ) }
                    </div>
                </div>
                <div className = "mt-3">
                    {fileCreateDate.map((date) => (
                        <div key={date.metadataDate} style = {{ height: "auto" }}>
                            <label style = {{ margin: "0.5vw" }}>{ date.metadataDate }</label>
                            <div className = "row" style = {{ width: "100%", margin: "auto" }}>
                                { groupFile
                                    .filter((file) => file.fileModifiedDate === date.metadataDate)
                                    .map((file, index) => (
                                    <div key={file.fileId} className = "col-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2" style = {{ marginTop: "1vh" }}>
                                        <CustomImage imagePath = { file.img } onClick = { () => clickImage(file.fileId, index) } />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    <CustomImageModal isOpen = { isImageModal ? "open" : "close" } onClick = { modalBackgroundClick }>
                        <div 
                            className = {`imageDetailView`} 
                            style = {{ 
                                display: "flex",
                                width: "60vw"
                            }}
                        >
                            <CustomCarousel 
                                imageList = { groupFile } 
                                clickImage = { imageDetail } 
                                imgIndex = { imgIndex } 
                                style = {{  
                                    
                                }} 
                            />
                        </div>
                    </CustomImageModal>
                </div>
            </div>
            { isToastOpen && <Toast resultCode = { resultSet.resultCode } resultMessage = { resultSet.resultMessage } /> }
        </div>
    );
}
export default ViewGroup;