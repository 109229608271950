import React from 'react';
import '../../css/common/button/CustomButton.css';

export const CustomButton = ({ buttonText, onClick }) => {
    return(
        <div className = "custom-button-container">
            <button 
                className = "custom-button" 
                type = "button" 
                onClick = { onClick }
            >
                { buttonText }
            </button>
        </div>
    );
};